/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
  filterHandlers = {
    update: 'cardsDatabaseFilter/updateFilter',
    reset: 'cardsDatabaseFilter/resetFilter',
    resetState: 'cardsDatabaseFilter/resetFilterState',
    removeSelected: 'cardsDatabaseFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setClassName('fullName', 'row-1');
    filter.setAction('fullName', 'cardsDatabaseFilter/updateSearch');

    filter.createSelectEntity('color');
    filter.setTitle('color', 'По типу карточки');
    filter.setName('color', 'select_color');
    filter.setPreviewName('color', 'Тип');
    filter.setInitAction('color', 'cardsDatabase/initCardColorFilter');
    filter.setIconClassName('color', 'icon-attention');
    filter.setClassName('color', 'row-2');
    filter.setAction('color', 'cardsDatabase/updateColorFilter');
    filter.setClearAction('color', 'cardsDatabaseFilter/resetSelect');

    filter.createMultiSelectEntity('type');
    filter.setTitle('type', 'По причине выдачи');
    filter.setName('type', 'multi_select_type');
    filter.setPreviewName('type', 'Причина');
    filter.setInitAction('type', 'cardsDatabase/initCardTypeFilter');
    filter.setIconClassName('type', 'icon-thumbs');
    filter.setClassName('type', 'row-3');
    filter.setAction('type', 'cardsDatabaseFilter/updateMultiSelect');

    this.filterModel = filter.filterModel;
  }
}
