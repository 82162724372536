import { Mutation, Action, Module, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import moment from 'moment';

import { getCardsList } from '@/api/cards';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { PageSort } from '@/lib/layouts/page/page.interface';
import CardsDatabaseFilter from './filterEntity';
import filterModel from './filter';
import databaseEntity from './databaseEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { CardTypeItem } from '@/interfaces/models/cards.interface';
import { UITable } from '@/lib/util/tableUtils';
import { strings } from '@/lib/stringConst';
import { CheckboxTypeReason } from '@/interfaces/filter.interface';

export const MODULE_NAME = 'cardsDatabase';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CardsDatabaseModule extends PageBaseModule {
  filter: Filter;
  date = {
    key: 'date',
    type: 'text',
    title: '',
    value: '',
    action: 'cardsDatabase/updateDate',
    placeholder: 'Дата',
    required: true,
    size: 40,
    mask: {
      regex: '\\d*',
      placeholder: ' ',
      minChars: 1,
    },
    error: {
      class: '',
      message: '',
    },
    validation: {
      empty: '',
    },
  };

  constructor(module: CardsDatabaseModule) {
    super(module);

    const page = new PageEntity();
    this.pageSettings = page.values;
    page.values.actionPagination = 'cardsDatabase/updatePage';
    this.pageSettings.actionsHandler = {
      additional: {
        reason: 'edit',
      },
    };

    const filter = new filterModel();
    this.filter = getModule(CardsDatabaseFilter);
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterName('cardsDatabaseFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
    this.filter.setBtnClassName('row-4');
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.cardsDatabaseSort = JSON.stringify(sorts);
  }

  @Mutation
  UPDATE_DATE(date: string) {
    this.date.value = date;
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit(
      'SET_SORT',
      window.localStorage.cardsDatabaseSort ? JSON.parse(window.localStorage.cardsDatabaseSort) : {}
    );

    await this.initCardsFilterInfo();
    await this.filter.init();

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    this.getList();
  }

  @Action({ rawError: true })
  initDateFilter() {
    const date = moment().format('DD/MM/YY');
    this.context.commit('UPDATE_DATE', date);
  }

  @Action({ rawError: true })
  async initCardsFilterInfo() {
    await databaseEntity.getCardTypesList();
  }

  @Action({ rawError: true })
  initCardColorFilter() {
    return (
      databaseEntity.model.cardType.list &&
      databaseEntity.model.cardType.list.map((item: { id: string; value: string }) => {
        return {
          id: item.id,
          name: item.value,
          value: item.value,
          checked: false,
        };
      })
    );
  }

  @Action({ rawError: true })
  async updateColorFilter(params: { key: string; value: { id: string; value: string } }) {
    this.filter.updateSelect(params);

    if (!params.value.value) {
      this.filter.resetMultiSelect('type');

      return;
    }

    const list = await this.getCardReasonList(params.value.id);
    this.filter.updateMultiSelectList({ key: 'type', list: list });
  }

  @Action({ rawError: true })
  getCardReasonList(color: string): CheckboxTypeReason[] {
    return databaseEntity.cardTypes[color].map((item: CardTypeItem) => {
      return {
        id: item.type,
        name: item.name,
        value: item.type,
        checked: false,
      };
    });
  }

  @Action({ rawError: true })
  initCardTypeFilter() {
    const currentColor = window.localStorage.cardsDatabaseFilterColor
      ? JSON.parse(window.localStorage.cardsDatabaseFilterColor)
      : '';
    const list = currentColor && currentColor.value ? this.getCardReasonList(String(currentColor.id)) : [];

    return list;
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const filter = await this.getFilterForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getCardsList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  getFilterForRequest() {
    const dateFilter = this.date.value
      ? `&filters[0][id]=day&filters[0][value]=${moment(this.date.value, 'DD/MM/YY').format('YYYY-MM-DD')}`
      : '';

    return `${dateFilter}${this.filter.filterSettings.filter}`;
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    const result = await this.prepareTable(table);
    this.context.commit('SET_TABLE', result);
  }

  @Action({ rawError: true })
  prepareTable(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);

    return uiTable
      .setProperty('typeName', 'name', 'Причина выдачи')

      .removeColumn('count')
      .removeColumn('method')
      .removeColumn('isTest')

      .changeTitleOrder('day', 0)

      .setSortableValues(sorts)

      .getTable() as any;
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  async updateDateFilter(params: { day: string; month: string; year: string }) {
    const newDate = moment(`${params.day}-${params.month}-${params.year}`, 'DD-MM-YYYY').format('DD/MM/YY');
    await this.context.commit('UPDATE_DATE', newDate);

    this.getList();
  }

  @Action({ rawError: true })
  async resetDateFilter() {
    await this.context.commit('UPDATE_DATE', '');

    this.getList();
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    await this.getList();
  }
}

export default getModule(CardsDatabaseModule);
