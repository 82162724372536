
import { Component, Vue } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AddIcon from '@/components/icons/Add.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import IconFont from '@/components/icons/IconFont.vue';

import CardsModule from '@/store/cards/index';
import CardsDatabaseModule from '@/store/cards/database/index';
import CardsDatabaseEntity from '@/store/cards/database/databaseEntity';
import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import CardWithCount from '@/components/table-items/CardWithCount.vue';
import { dateFormat } from '@/lib/Utils';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    CardWithCount,
    TextDatetime,
    AppTable,
    TabsNav,
    AddIcon,
    AppHeaderMain,
    DatePicker,
    IconFont,
  },
})
export default class FinanceCustomerMain extends Vue {
  get tabsNav() {
    return CardsModule.tabsNav;
  }

  get currentTab() {
    return CardsModule.tabsNav.cards_database?.id;
  }

  get settings() {
    return CardsDatabaseModule.pageSettings;
  }

  get filterSettings() {
    return CardsDatabaseModule.filter.filterSettings;
  }

  get table() {
    return CardsDatabaseModule.pageSettings.table;
  }

  get date() {
    return CardsDatabaseModule.date;
  }

  getColorCard(item: { method: string; count: number }) {
    if (item.method === 'canceled') return 'grey';

    if (item.count > 0) return 'green';

    if (item.count === 0) return 'red';

    return 'yellow';
  }

  sort(header: { id: string; sort: { value: string } }) {
    CardsDatabaseModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async toEdit(item: Record<string, string>) {
    if (!item) {
      return;
    }

    await CardsDatabaseEntity.updateEditCard({
      ...item,
      fullName: item.fio,
      day: dateFormat(item.day, { to: 'DD/MM/YY' }),
    });
    await this.$router.push({ name: 'cards_database_edit', params: { cardId: item.id } });
  }

  selectAmount(value: string) {
    CardsDatabaseModule.updatePageAmountItems(value);
  }

  selectDateStart(date: { day: string; month: string; year: string }) {
    CardsDatabaseModule.updateDateFilter(date);
  }

  async updateList() {
    await CardsDatabaseModule.setPageToBegin();
    await CardsDatabaseModule.filter.updateFilter();
    await CardsDatabaseModule.getList();
  }

  async resetDateFilter() {
    await CardsDatabaseModule.resetDateFilter();
  }

  async resetFilters() {
    await CardsDatabaseModule.setPageToBegin();
    await CardsDatabaseModule.clearSort();
    await CardsDatabaseModule.filter.resetFilter();
    await CardsDatabaseModule.getList();
  }

  mounted() {
    CardsModule.initTabsItems();
    CardsDatabaseModule.init();
  }
}
