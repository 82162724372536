import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex cp-margin__t--medium" }
const _hoisted_3 = { class: "cp-flex cp-flex--align-center mr-instant-filter-wrapper" }
const _hoisted_4 = { class: "cp-date-filter" }
const _hoisted_5 = { class: "cp-flex align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_7 = { class: "cp-margin__l--sx" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_card_with_count = _resolveComponent("card-with-count")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-payments-wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createVNode(_component_router_link, { to: { name: 'cards_database_add' } }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_add_icon, {
                  class: "cp-cursor-pointer",
                  color: "#9B9BC7"
                }, null, 512), [
                  [
                    _directive_tooltip,
                    'Добавить карточку',
                    void 0,
                    { "bottom-end": true }
                  ]
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(), _createBlock(_component_date_picker, {
                  key: `datepicker_${_ctx.date.key}`,
                  data: _ctx.date,
                  onClick: _ctx.selectDateStart,
                  onInit: _ctx.selectDateStart
                }, null, 8, ["data", "onClick", "onInit"]))
              ]),
              _createElementVNode("div", {
                class: "cp-icon-primary cp-cursor-pointer cp-flex cp-margin__l--small",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetDateFilter && _ctx.resetDateFilter(...args)))
              }, [
                _createVNode(_component_icon_font, {
                  size: "11",
                  icon: "close"
                })
              ])
            ]),
            _createVNode(_component_app_header_main, {
              class: "cp-margin__t--no",
              "filter-settings": _ctx.filterSettings,
              "is-to-add-hidden": true,
              onUpdateList: _ctx.updateList,
              onResetFilters: _ctx.resetFilters
            }, null, 8, ["filter-settings", "onUpdateList", "onResetFilters"])
          ])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-day": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-to": "DD/MM/YYYY"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-fio": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'employees_schedule', params: { employeeId: item.employeeId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-typeName": _withCtx(({ item }) => [
              _createElementVNode("div", null, [
                _withDirectives(_createVNode(_component_card_with_count, {
                  count: Math.abs(Number(item.count)),
                  color: _ctx.getColorCard(item),
                  onClick: ($event: any) => (_ctx.toEdit(item))
                }, null, 8, ["count", "color", "onClick"]), [
                  [
                    _directive_tooltip,
                    {
              content: 'Карточки, начисленные по данной причине<br>Нажать для редактирования',
              html: true,
            },
                    void 0,
                    { bottom: true }
                  ]
                ]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(item.typeName), 1)
              ])
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}